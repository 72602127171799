import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter)

export default new VueRouter({
    routes:[
        {
            path: '/',
            name: '首页',
            component: (resolve) => require(['@/views/home/index'], resolve)
        }
    ]
})
